<template>
	<div class="pagination__holder">
		<div v-if="showList"></div>
		<div class="pagination">
			<div class="pagination__button">
				<img src="../../assets/icons/arrow-left-max.svg" alt="" />
			</div>
			<div class="pagination__button">
				<img src="../../assets/icons/arrow-left-short.svg" alt="" />
			</div>
			<ul class="pagination__list flex-wrap">
				<li
					v-for="page in pagesList"
					:key="page"
					class="pagination__item"
					:class="{ 'pagination__item--active': page === numValue }"
					@click="$emit('input', page)"
				>
					{{ page }}
				</li>
				<li class="pagination__item pagination__item--disabled">...</li>
				<li class="pagination__item" @click="$emit('input', numLastPage)">{{ numLastPage }}</li>
			</ul>
			<div class="pagination__button pagination__button--next">
				<img src="../../assets/icons/arrow-left-short.svg" alt="" />
			</div>
			<div class="pagination__button pagination__button--next">
				<img src="../../assets/icons/arrow-left-max.svg" alt="" />
			</div>
		</div>
		<div class="show-list" v-if="showList">
			<AppText size="14" weight="600" class="mr-15"> {{ $t("show") }}:</AppText>
			<form-label
				v-model="show"
				:list="categoryList"
				component-type="dropdown"
				class="mb-10 mt-0"
				style="min-width: 100px"
			/>
		</div>
	</div>
</template>

<script>
import "../../assets/styles/components/app-pagination.scss";
import AppText from "./AppText";
import FormLabel from "./FormLabel";

export default {
	name: "AppPagination",
	components: { FormLabel, AppText },
	props: {
		value: {
			type: [String, Number],
			default: 1,
		},
		lastPage: {
			type: [String, Number],
			default: 15, // change later to something small
		},

		showList: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			show: "20",
			categoryList: [
				{
					name: "10",
					value: 10,
				},
				{
					name: "20",
					value: 20,
				},
				{
					name: "30",
					value: 30,
				},
			],
		};
	},
	computed: {
		numValue() {
			return +this.value;
		},
		numLastPage() {
			return +this.lastPage;
		},
		pagesList() {
			return 5;
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination__holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	grid-gap: 15px;
	width: 100%;
}

.show-list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: flex-end;
}

@media (max-width: 700px) {
	.pagination__holder {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
</style>
